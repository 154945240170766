<template>
  <div class="modal-body gallery-modal">
    <button type="button" class="gallery-modal__close" @click="$emit('close')">
      <RemixIconComponent category="system" name="close-fill" />
    </button>
    <template v-if="images.length > 1">
      <button type="button" class="gallery-modal__arrow gallery-modal__arrow--left" @click="prev">
        <RemixIconComponent category="system" name="arrow-left-s-line" />
      </button>
      <button type="button" class="gallery-modal__arrow gallery-modal__arrow--right" @click="next">
        <RemixIconComponent category="system" name="arrow-right-s-line" />
      </button>
    </template>
    <a :href="getImgPath(images[i])" target="_blank" type="button" class="gallery-modal__external">
      <IconComponent name="external" />
    </a>
    <img :src="getImgPath(images[i])" :alt="images[i].alt" class="gallery-modal__image" />
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "GalleryModal",
  props: {
    index: Number,
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    i: 0,
  }),
  mounted() {
    this.i = this.index || 0;
  },
  methods: {
    prev() {
      if (this.i - 1 < 0) {
        this.i = this.images.length - 1;
      } else {
        this.i -= 1;
      }
    },
    next() {
      if (this.i + 1 > this.images.length - 1) {
        this.i = 0;
      } else {
        this.i += 1;
      }
    },
    getImgPath(image) {
      if (image.path.indexOf("https://") === -1) {
        return this.$store.state._env.HOST_ENDPOINT + image.path;
      }
      return image.path;
    },
  },
  components: { RemixIconComponent, IconComponent },
};
</script>

<style lang="stylus">
.gallery-modal {
  padding 0
  margin 10px 0 0
  position relative
  max-width 700px
  width 100%
  min-width 200px
  min-height 250px
  +below(1024px) {
    margin-top: 60px;
  }
  +below(788px) {
    max-width calc(100% - 150px)
  }

  &__image {
    width 100%
    height 100%
    background var(--gray)
    border-radius 10px
    border none
    outline none
    box-shadow none
    object-fit contain
  }

  &__close {
    width 50px
    height 50px
    border-radius 100%
    background var(--white)
    absolute right top -55px
    border: 1px solid var(--black_o1);
    display inline-flex
    opacity 0.5
    align-items center
    justify-content center

    .icon {
      width 22px
      height 22px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__external {
    width 50px
    height 50px
    border-radius 100%
    background var(--white)
    absolute right 5px top 5px
    border: 1px solid var(--black_o1);
    display inline-flex
    align-items center
    justify-content center
    opacity 0.3
    transition opacity var(--transition)

    &:hover {
      opacity 1
    }

    .icon {
      width 22px
      height 22px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__arrow {
    absolute top bottom
    height 50px
    width 50px
    border-radius 100%
    background var(--white)
    border: 1px solid var(--black_o1);
    display inline-flex
    align-items center
    justify-content center
    margin auto
    opacity 0.5
    cursor pointer

    .icon {
      width 22px
      height 22px

      svg path {
        fill var(--dark)
      }
    }

    &--right {
      right 5px
    }

    &--left {
      left 5px
    }
  }
}
</style>
