<template>
  <div :class="cls" v-if="haveBlocks">
    <template v-for="({ data, type, id }, i) in parsedText.blocks">
      <p :class="`${cls}__${type}`" :key="i" v-if="type === 'paragraph'" v-html="data.text" :id="id"></p>
      <div :class="`${cls}__${type}`" v-if="type === 'embed' && data.embed" :key="i" :id="id">
        <span v-html="data.caption"></span>
        <iframe :src="data.embed"></iframe>
      </div>
      <h2
        :id="id"
        :key="i"
        v-if="type === 'header' && data.level === 2"
        :class="`${cls}__${type} ${cls}__${type}--${data.level}`"
      >
        {{ data.text }}
      </h2>
      <h3
        :id="id"
        :key="i"
        v-if="type === 'header' && data.level === 3"
        :class="`${cls}__${type} ${cls}__${type}--${data.level}`"
      >
        {{ data.text }}
      </h3>
      <h4
        :id="id"
        :key="i"
        v-if="type === 'header' && data.level === 4"
        :class="`${cls}__${type} ${cls}__${type}--${data.level}`"
      >
        {{ data.text }}
      </h4>
      <h5
        :id="id"
        :key="i"
        v-if="type === 'header' && data.level === 5"
        :class="`${cls}__${type} ${cls}__${type}--${data.level}`"
      >
        {{ data.text }}
      </h5>
      <h6
        :id="id"
        :key="i"
        v-if="type === 'header' && data.level === 6"
        :class="`${cls}__${type} ${cls}__${type}--${data.level}`"
      >
        {{ data.text }}
      </h6>
      <pre :id="id" :key="i" v-if="type === 'code'" :class="`${cls}__${type}`">{{ data.code }}</pre>
      <div :id="id" :key="i" v-if="type === 'quote'" :class="`${cls}__${type}`">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g>
            <path
              d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
            />
          </g>
        </svg>
        <div :class="`${cls}__${type}-text`">{{ data.text }}</div>
        <div :class="`${cls}__${type}-header`">
          <img v-if="data.image" :src="data.image.url" :class="`${cls}__${type}-image`" :alt="data.caption" />
          <div :class="`${cls}__${type}-author`">
            <span>{{ data.caption }}</span>
            <span>{{ data.post }}</span>
          </div>
        </div>
      </div>
      <hr :id="id" :key="i" v-if="type === 'delimiter'" :class="`${cls}__${type}`" />
      <div :id="id" :key="i" v-if="type === 'raw'" :class="`${cls}__${type}`" v-html="data.html"></div>
      <div :id="id" :key="i" v-if="type === 'warning'" :class="`${cls}__${type}`">
        <span>{{ data.title }}</span>
        <p>{{ data.message }}</p>
      </div>
      <div v-if="type === 'table'" :key="i" :class="`${cls}__${type}`">
        <table :id="id" :key="i">
          <tbody>
            <tr v-for="(row, i) in data.content" :key="i">
              <td v-for="(column, j) in row" :key="j" v-html="column"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul
        :id="id"
        :key="i"
        v-if="type === 'list' && data.style === 'unordered'"
        :class="`${cls}__${type}--${data.style}`"
      >
        <li v-for="(item, i) in data.items" :key="i" v-html="item"></li>
      </ul>
      <ol
        :id="id"
        :key="i"
        v-if="type === 'list' && data.style === 'ordered'"
        :class="`${cls}__${type}--${data.style}`"
      >
        <li v-for="(item, i) in data.items" :key="i" v-html="item"></li>
      </ol>
      <a
        :id="id"
        :key="i"
        v-if="type === 'linkTool' && data && data.meta && data.meta.title"
        :href="data.link"
        target="_blank"
        :class="`${cls}__${type}`"
        rel="nofollow"
      >
        <div :class="`${cls}__${type}-content`">
          <span>{{ data.meta.title.length ? data.meta.title : data.link }}</span>
          <span v-if="data.meta.description.length">{{ data.meta.description }}</span>
        </div>
        <img v-if="data.meta.image.url.length" :src="data.meta.image.url" :alt="data.meta.title" />
      </a>
      <ul
        :id="id"
        :class="`${cls}__${type}-${data.config}`"
        :style="{
          display: 'grid',
          'grid-template-columns': `repeat(${data.countItemEachRow || 1}, 1fr)`,
        }"
        v-if="type === 'carousel' && data.config === 'standard' && data.items"
        :key="i"
      >
        <li v-for="(img, i) in data.items" :key="i">
          <a :href="img.url" :class="`${cls}__image`" @click.prevent="showGallery(data.items, i)">
            <img :src="img.url" :alt="img.caption" />
            <span v-if="img.caption">{{ img.caption }}</span>
          </a>
        </li>
      </ul>
      <div
        :id="id"
        :class="`${cls}__${type}-${data.config}`"
        v-if="type === 'carousel' && data.config === 'carousel' && data.items"
        :key="i"
      >
        <div class="swiper">
          <div class="swiper-wrapper">
            <a
              v-for="(img, i) in data.items"
              :key="i"
              class="swiper-slide"
              :href="img.url"
              @click.prevent="showGallery(data.items, i)"
            >
              <img :src="img.url" :alt="img.caption" />
              <span v-if="img.caption">{{ img.caption }}</span>
            </a>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <ul
        :id="id"
        :class="`${cls}__${type}-${data.config}`"
        :style="{
          display: 'block',
          'column-count': data.countItemEachRow,
        }"
        v-if="type === 'carousel' && data.config === 'masonry' && data.items"
        :key="i"
      >
        <li v-for="(img, i) in data.items" :key="i">
          <a :href="img.url" @click.prevent="showGallery(data.items, i)">
            <img :src="img.url" :alt="img.caption" />
            <span v-if="img.caption">{{ img.caption }}</span>
          </a>
        </li>
      </ul>
      <a
        :id="id"
        :key="i"
        v-if="type === 'image' && data.file"
        :class="`${cls}__${type}`"
        @click.prevent="showGallery([data.file], i)"
        :href="data.file.url"
        target="_blank"
      >
        <img :src="data.file.url" :alt="data.caption" />
        <span v-html="data.caption"></span>
      </a>
      <!--      <EditorJSPollRenderer-->
      <!--        :key="i"-->
      <!--        :data="data"-->
      <!--        :id="id"-->
      <!--        v-if="type === 'poll' && data.answers && data.answers.length"-->
      <!--      />-->
      <div :id="id" :class="`${cls}__${type}`" :key="i" v-if="type === 'video' && data.video">
        <span>{{ data.title }}</span>
        <video controls :src="data.video.url"></video>
      </div>
      <div :id="id" :class="`${cls}__${type}`" :key="i" v-if="type === 'audio' && data.audio">
        <span>{{ data.title }}</span>
        <img :src="data.cover.url" v-if="data.cover" alt="" />
        <audio controls :src="data.audio.url"></audio>
      </div>
    </template>
  </div>
</template>

<script>
import GalleryModal from "components/modals/components/GalleryModal.vue";
// import EditorJSPollRenderer from "components/EditorJSPollRenderer.vue";

export default {
  name: "EditorJSComponent",
  // components: { EditorJSPollRenderer },
  data: () => ({
    cls: "editor-js-renderer",
    answeredPolls: [],
    sliders: {},
    sessionHash: "",
  }),
  props: {
    text: {
      type: [Object, String],
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    nbspAvailable: Boolean,
  },
  computed: {
    haveBlocks() {
      return (
        this.parsedText &&
        typeof this.parsedText === "object" &&
        this.parsedText.blocks &&
        this.parsedText.blocks.length
      );
    },
    parsedText() {
      let text = this.text;
      try {
        if (!this.nbspAvailable) {
          text = text.replace(/&nbsp;/g, " ");
        }
        return JSON.parse(text);
      } catch (e) {
        return text;
      }
    },
  },
  mounted() {
    if (
      this.haveBlocks &&
      this.parsedText.blocks.findIndex((b) => b.type === "carousel" && b.data.config === "carousel") >= 0
    ) {
      require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
        this.parsedText.blocks
          .filter((b) => b.type === "carousel" && b.data.config === "carousel")
          .forEach((b) => {
            this.sliders[b.id] = new Swiper(document.querySelector("#" + b.id + " .swiper"), {
              autoHeight: true,
              spaceBetween: 16,
              slidesPerView: "auto",
              navigation: {
                nextEl: "#" + b.id + " .swiper-button-next",
                prevEl: "#" + b.id + " .swiper-button-prev",
              },
              pagination: {
                el: "#" + b.id + " .swiper-pagination",
                dynamicBullets: true,
              },
              breakpoints: {
                640: {
                  slidesPerView: b.data.countItemEachRow || 1,
                },
              },
            });
          });
      });
    }
  },
  methods: {
    showGallery(images, index = 0) {
      this.$store.state._modals = [
        {
          component: GalleryModal,
          options: {
            images: images.map((item) => ({
              path: item.url,
              alt: item.caption,
            })),
            index,
          },
        },
      ];
    },
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.editor-js-renderer {
  display flex
  flex-direction column
  grid-gap 20px

  .swiper-button-next, .swiper-button-prev {
    color var(--orange)
  }

  .swiper-pagination-bullet-active {
    background var(--orange)
  }

  &__paragraph {
    margin 0
    padding 0
    width 100%
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);
  }

  &__warning {
    padding 30px 50px
    display grid
    gap 10px
    width 100%
    background #F2F2F2
    border-left 5px solid var(--orange)

    span {
      font-weight: 500;
      font-size: 1em;
      line-height: 26px;
      color: var(--dark);
    }

    p {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 22px;
      color: var(--dark-light);
    }
  }

  &__video {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding 10px
    border-radius var(--main_radius)
    display grid
    gap 10px
    width 100%

    video {
      border-radius var(--main_radius)
      width 100%
    }

    span {
      font-weight: 700;
      font-size: 1.375em;
      line-height: 28px;
      color: var(--dark);
    }
  }

  &__audio {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding 10px
    border-radius var(--main_radius)
    display flex
    flex-direction column
    align-items flex-start
    gap 10px
    width 100%

    audio {
      border-radius var(--main_radius)
      width 100%
    }

    img {
      width 100%
      height auto
      border-radius var(--main_radius)
      object-fit cover
      object-position center
    }

    span {
      font-weight: 700;
      font-size: 1.375em;
      line-height: 28px;
      color: var(--dark);
    }
  }

  &__table {
    width 100%
    overflow-x auto

    table {
      width 100%
      tr td {
        padding 3px 10px
        border-bottom 1px solid var(--gray-divider)
      }
    }
  }

  &__linkTool {
    width 100%
    padding 15px
    background var(--main)
    display flex
    flex-direction column
    gap 8px
    box-shadow 0 0 1px var(--dark-light)
    box-sizing border-box
    border-radius var(--main_radius)
    text-decoration none
    color var(--dark)
    align-items center

    &:hover {
      box-shadow 0 0 1px var(--red)
      color var(--white)
    }
    &:active {
      transform scale(0.97)
    }

    &-content {
      display flex
      flex-direction column

      span:first-child {
        font-size 1.125em
        font-weight bold
      }
    }

    img {
      width 80px
      height 80px
      overflow hidden
      flex-shrink 0
      border-radius var(--main_radius)
    }
  }

  &__delimiter {
    text-align center
    border none
    background none
    position relative
    &:before {
      display: inline-block;
      content: "***";
      font-size: 30px;
      line-height: 65px;
      height: 30px;
      letter-spacing: 0.2em;
    }
  }

  &__image {
    width 100%
    height auto
    display grid
    grid-gap 8px

    img {
      width 100%
      height 100%
      border-radius var(--main_radius)
    }

    span {
      width 100%
      text-align right
      font-size 0.875em
      line-height normal
      color var(--dark-light)
    }
  }

  &__carousel-carousel {
    .swiper-slide {
      display grid
      border-radius var(--main_radius)

      img {
        width 100%
        height auto
        object-fit cover
        object-position center
        border-radius var(--main_radius)
      }
    }
  }

  &__carousel-standard {
    gap 10px
    +below(640px) {
      grid-template-columns 1fr !important
    }

    img {
      height 200px
      object-fit cover
      object-position center
      border-radius var(--main_radius)
    }
  }

  &__carousel-masonry {
    column-gap 10px
    width 100%
    +below(640px) {
      column-count 1 !important
    }

    img {
      object-fit cover
      object-position center
      border-radius var(--main_radius)
    }

    li {
      width 100%
      break-inside: avoid-column;
      margin-bottom 10px
    }
  }

  &__header {
    font-weight: 700;
    width 100%
    color: var(--dark);
    margin 0
    padding 0

    &--2 {
      font-size: 28px;
      line-height: 36px;
    }

    &--3 {
      font-size: 22px;
      line-height: 28px;
    }

    &--4 {
      font-size: 18px;
      line-height: 22px;
    }

    &--5 {
      font-size: 16px;
      line-height: 18px;
    }

    &--6 {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__list--unordered {
    padding-left 10px
    list-style-type disc
    list-style-position inside

    ol {
      list-style-type lower-latin
      list-style-position inside
      margin-left 15px
    }
    ul {
      list-style-type circle
      list-style-position inside
      margin-left 15px
    }
  }

  &__code {
    padding 10px
    background #FCF0EF
    font-family monospace
    font-size 0.875rem
    border-radius var(--main_radius)
  }

  &__list--ordered {
    padding-left 10px
    list-style-type: decimal;
    list-style-position: inside;

    ol {
      list-style-type lower-latin
      list-style-position inside
      margin-left 15px
    }
    ul {
      list-style-type circle
      list-style-position inside
      margin-left 15px
    }
  }

  &__embed {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding 10px
    border-radius var(--main_radius)
    display flex
    flex-direction column
    align-items flex-start
    gap 10px
    width 100%

    span {
      font-weight: 700;
      font-size: 1.375em;
      line-height: 28px;
      color: var(--dark);
    }

    iframe {
      border-radius var(--main_radius)
      border none
      width 100%
      max-width 100%
      height 350px
      +below(580px) {
        height 270px
      }
      +below(420px) {
        height 230px
      }
    }
  }

  &__quote {
    position relative
    display grid
    gap 10px
    background: var(--gray-100, #F7F7F7);
    padding 32px
    border-radius 16px

    svg {
      width 48px
      height 48px

      path {
        fill #A5A5A5
      }
    }

    &-text {
      font-size: 1.25em;
      line-height: 140%;
      color: var(--dark);
    }

    &-header {
      display flex
      align-items center
      gap 8px
    }

    &-image {
      width 64px
      height 64px
      border-radius 8px
      object-fit cover
      object-position center
    }

    &-author {
      display grid
      align-items center

      span:first-child {
        font-weight: 600;
        font-size: 1em;
        line-height: 140%;
        color: var(--dark);
      }
      span:last-child {
        font-size 0.875em
        line-height 120%
        color: var(--body_muted, #919191);
      }
    }
  }
}
</style>
