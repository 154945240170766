<template>
  <main class="static-page page container-padding">
    <h1 v-if="data.title">{{ data.title }}</h1>
    <div class="static-page__content">
      <EditorJSComponent :text="data.description" />
    </div>
  </main>
</template>

<script>
import GET from "@/graphql/pages/landing/StaticPage.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "StaticPage",
  components: { EditorJSComponent },
  async asyncData({ apollo, route, store }) {
    await apollo.clients.landing
      .query({
        query: GET,
        variables: {
          id: route.params.id ? parseInt(route.params.id) : undefined,
          type_id: route.query.type ? parseInt(route.query.type) : undefined,
        },
      })
      .then(({ data }) => {
        store.state.static_page = data.acts_item;
      });
  },
  // data() {
  //   return {
  //     title: null,
  //     content: null,
  //   };
  // },
  computed: {
    data() {
      return this.$store.state.static_page;
    },
  },
  metaInfo() {
    let title = this.data.title;
    return {
      title,
      meta: [
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
      ],
    };
  },
};
</script>

<style lang="stylus"></style>
